import tw from 'tailwind-styled-components';
import styled from 'styled-components';
import { Text as Paragraph, Title } from '../Typography';

export const Container = styled.div`
  width: 100%;
  .subcategory-slider {
    .slick-track {
      float: left;
    }
  }
`;
export const GridContent = tw.div`
  text-center
  md:mb-30
  sm:mb-50
`;
export const SubHeading = tw(Paragraph)`
  mb-2.5
  text-darkgray
  opacity-70
`;
export const Heading = tw(Title)`
  mb-5
`;

export const CarouselCard = styled.div`
  margin-right: 20px;
  min-width: 84%;
  &:last-child {
    margin-right: 0;
  }
  @media (min-width: 768px) {
    min-width: 40%;
  }
  @media (min-width: 1024px) {
    min-width 22%;
  }
`;
CarouselCard.displayName = 'CarouselCard';
